import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

export default function TeamMember(props) {
  return (
    <div className={styles.member}>
      <img className={styles.member__avatar} alt={props.name} src={props.avatar}></img>
      <h3 className={styles.member__name}>{props.name}</h3>
      <p className={styles.member__details}>
        {props.title}
        <br />
        {props.email}
      </p>
    </div>
  );
}

TeamMember.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
};

TeamMember.defaultProps = {
  avatar:
    'https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
  name: 'Anne-Marlies',
  title: 'Slimmerik',
  email: 'anne-marlies@dev.kitchen',
};

import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import StepColumn from '../step-column';

export default function Steps(props) {
  return (
    <section className={`${styles.steps} ${props.className}`} id={props.id}>
      <h2 className={styles.steps__title}>{props.title}</h2>
      <div className={styles.steps__items}>
        <StepColumn
          illustration="/illustrations/illustration-home.svg"
          title="1. Huisbezoek &amp; Advies"
          paragraph="Tijdens een huisbezoek horen wij graag wat uw wensen zijn. Wij adviseren u welke slimmigheden er allemaal mogelijk zijn om uw comfort, veiligheid en energiebesparing te verhogen."
        ></StepColumn>
        <StepColumn
          illustration="/illustrations/illustration-package.svg"
          title="2. Ontwerp &amp; Offerte"
          paragraph="Op basis van uw wensen maken wij een ontwerp van uw slimme huis in combinatie met scenario’s. Ook ontvangt u de offerte met een kostenoverzicht."
        ></StepColumn>
        <StepColumn
          illustration="/illustrations/illustration-expert.svg"
          title="3. Realisatie"
          paragraph="Onze Slimmerik komt met de producten bij u thuis en installeert deze voor u volgens het door u goedgekeurde ontwerp. Onze Slimmerik gaat pas weg als alles naar wens werkt en al uw vragen beantwoord zijn."
        ></StepColumn>
      </div>
    </section>
  );
}

Steps.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
};

Steps.defaultProps = {
  title: 'In 3 stappen naar een Smart Home',
  id: 'stappen',
};

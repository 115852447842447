import React from 'react';

import SEO from '../components/seo';
import styles from './styles.module.scss';
import ContactForm from '../components/contact-form';
import Hero from '../components/hero';
import Products from '../components/products';
import Testimonials from '../components/testimonials';
import InformationBlock from '../components/information-block';
import Faq from '../components/faq';
import SmartHome from '../components/smart-home-cta';
import Steps from '../components/steps';
import Advice from '../components/advice';
import Advantages from '../components/advantages';
import Team from '../components/team';

export default function IndexPage() {
  return (
    <React.Fragment>
      <SEO title="Home"></SEO>
      <div className={styles.home}>
        <Hero className={styles.home__hero}></Hero>
        <InformationBlock id="diensten" className={styles.home__information}></InformationBlock>
        <Steps id="werkwijze" className={styles.home__steps}></Steps>
        <Advantages id="voordelen" className={styles.home__advantages}></Advantages>
        <Products id="oplossingen" className={styles.home__products}></Products>
        <Testimonials id="reviews" className={styles.home__testimonials}></Testimonials>
        <Advice id="advies" className={styles.home__advice}></Advice>
        <Team id="team" className={styles.home__team}></Team>
        <Faq id="faq" className={styles.home__faq}></Faq>
        <SmartHome id="download" className={styles.home__smart_home}></SmartHome>
        <ContactForm id="contact" className={styles.home__form}></ContactForm>
      </div>
    </React.Fragment>
  );
}

import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import Carousel from '../carousel';
import TeamMember from '../team-member';

export default function Team(props) {
  return (
    <section className={`${styles.team} ${props.className}`} id={props.id}>
      <Carousel title={props.title}>
        <TeamMember
          avatar="/images/Berry.jpg"
          title="Founder"
          name="Berry Moolenaar"
          email="berry@deslimmeriken.nl"
        ></TeamMember>
        <TeamMember
          avatar="/images/Mick.jpg"
          name="Mick Jonker"
          email="info@deslimmeriken.nl"
        ></TeamMember>
        <TeamMember
          avatar="/images/Anino.jpg"
          name="Anino Loche"
          email="info@deslimmeriken.nl"
        ></TeamMember>
      </Carousel>
    </section>
  );
}

Team.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
};

Team.defaultProps = {
  id: 'team',
  title: 'Onze Slimmeriken',
};

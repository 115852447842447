import React from 'react';
import styles from './styles.module.scss';
import { tns } from 'tiny-slider/src/tiny-slider';
import PropTypes from 'prop-types';

export default class Carousel extends React.Component {
  carousel;

  componentDidMount() {
    this.carousel = tns({
      container: '.carousel',
      preventScrollOnTouch: 'auto',
      items: 1,
      controlsContainer: '#carousel-controls',
      nav: false,
      autoHeight: true,
      gutter: 32,
      center: false,
      arrowKeys: true,
      mouseDrag: true,
      loop: false,
      controls: true,
      responsive: {
        768: {
          items: 2,
        },
        1024: {
          items: 3,
        },
      },
    });
  }

  componentWillUnmount() {
    this.carousel.destroy();
  }

  render() {
    return (
      <div className={styles.carousel}>
        <div className={styles.carousel__container}>
          <h2 className={styles.carousel__container__title}>{this.props.title}</h2>
          <div
            className={styles.carousel__container__controls}
            id="carousel-controls"
            aria-label="carousel Navigation"
          >
            <button
              className={styles.carousel__container__controls__button}
              aria-label="Previous Slide"
            >
              <span hidden={true} aria-hidden={true}>
                previous_slide_button
              </span>
              <svg width="24px" height="24px" viewBox="0 0 24 24">
                <path d="M.25,11.17l0,.06a.69.69,0,0,0-.1.19.38.38,0,0,0,0,.1A1.27,1.27,0,0,0,0,11.7a1.52,1.52,0,0,0,0,.6,1.27,1.27,0,0,0,.06.18.38.38,0,0,0,0,.1.69.69,0,0,0,.1.19l0,.06a1.45,1.45,0,0,0,.19.23l10.5,10.5a1.5,1.5,0,0,0,2.12-2.12L5.12,13.5H22.5a1.5,1.5,0,0,0,0-3H5.12l7.94-7.94a1.49,1.49,0,0,0,0-2.12,1.5,1.5,0,0,0-2.12,0L.44,10.94A1.45,1.45,0,0,0,.25,11.17Z"></path>
              </svg>
            </button>
            <button
              className={styles.carousel__container__controls__button}
              aria-label="Next Slide"
            >
              <span hidden={true} aria-hidden={true}>
                next_slide_button
              </span>
              <svg width="24px" height="24px" viewBox="0 0 24 24">
                <path d="M23.75,12.83l0-.06a.69.69,0,0,0,.1-.19.38.38,0,0,0,0-.1A1.27,1.27,0,0,0,24,12.3a1.51,1.51,0,0,0,0-.6,1.27,1.27,0,0,0-.06-.18.38.38,0,0,0,0-.1.69.69,0,0,0-.1-.19l0-.06a1.45,1.45,0,0,0-.19-.23L13.06.44a1.5,1.5,0,0,0-2.12,2.12l7.94,7.94H1.5a1.5,1.5,0,0,0,0,3H18.88l-7.94,7.94a1.49,1.49,0,0,0,0,2.12,1.5,1.5,0,0,0,2.12,0l10.5-10.5A1.45,1.45,0,0,0,23.75,12.83Z"></path>
              </svg>
            </button>
          </div>
        </div>

        <div className={`${styles.carousel__items} carousel`}>{this.props.children}</div>
      </div>
    );
  }
}

Carousel.propTypes = {
  title: PropTypes.string,
};

Carousel.defaultProps = {
  title: 'Onze experts',
};

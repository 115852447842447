import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import SecondaryButton from '../secondary-button';

import { ToastContainer, Slide, toast } from 'react-toastify';

import './toastify.css';

export default class ContactForm extends React.Component {
  state = {
    name: '',
    customerEmail: '',
    message: '',
    subscribeToNewsletter: false,
  };

  showToastMessage(message, type) {
    toast(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: type,
      closeButton: false,
    });
  }

  clearInputs() {
    this.setState({
      name: '',
      customerEmail: '',
      message: '',
      subscribeToNewsletter: false,
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (event) => {
    const target = event.target;
    const checked = target.checked;
    const name = target.name;

    this.setState({
      [name]: checked,
    });
  };

  isEmailValid(email) {
    var regex = /\S+@\S+\.\S+/;
    return regex.test(String(email).toLowerCase());
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const submitButton = document.getElementById('submit');

    if (
      this.state.customerEmail === '' ||
      this.state.name === '' ||
      this.state.message === '' ||
      this.isEmailValid(this.state.customerEmail) === false
    ) {
      this.showToastMessage('Gelieve alle verplichte velden in te vullen', toast.TYPE.ERROR);
      return;
    }

    submitButton.disabled = true;

    fetch(
      'https://deslimmeriken-website-prod-euw-functions.azurewebsites.net/api/sendMail?code=GO00l4HGiIwDA26aa9bxi13j7uHpSZP/AsI5f6YuIXI49bAGx8kBRQ==',
      {
        method: 'POST',
        body: JSON.stringify(this.state),
      },
    )
      .then(() => {
        this.showToastMessage('Gelukt!', toast.TYPE.SUCCESS);
        this.clearInputs();
        submitButton.disabled = false;
      })
      .catch(() => {
        this.showToastMessage('Oeps! Probeer het opnieuw', toast.TYPE.ERROR);
        submitButton.disabled = false;
      });
  };

  render() {
    return (
      <React.Fragment>
        <form className={`${styles.form} ${this.props.className}`} id={this.props.id}>
          <h2 className={styles.form__title}>Stuur ons een berichtje</h2>
          <div className={styles.form__container}>
            <input
              id="name"
              aria-label="name"
              type="text"
              name="name"
              placeholder="Naam"
              value={this.state.name}
              onChange={this.handleInputChange}
            ></input>
            <input
              id="customerEmail"
              aria-label="email"
              type="email"
              name="customerEmail"
              placeholder="E-mailadres"
              value={this.state.customerEmail}
              onChange={this.handleInputChange}
            ></input>
            <textarea
              id="message"
              aria-label="message"
              type="text"
              name="message"
              placeholder="Wat kunnen wij voor u betekenen?"
              rows="8"
              value={this.state.message}
              onChange={this.handleInputChange}
            ></textarea>
            <div className={styles.form__container__bottom}>
              <div className={styles.form__container__bottom__newsletter}>
                <input
                  type="checkbox"
                  id="subscribeToNewsletter"
                  name="subscribeToNewsletter"
                  checked={this.state.subscribeToNewsletter}
                  onChange={this.handleCheckboxChange}
                ></input>
                <label htmlFor="subscribeToNewsletter">Meld mij aan voor de nieuwsbrief</label>
              </div>
              <SecondaryButton id="submit" onClick={this.handleSubmit}>
                Verstuur
              </SecondaryButton>
            </div>
          </div>
        </form>
        <ToastContainer transition={Slide}></ToastContainer>
      </React.Fragment>
    );
  }
}

ContactForm.propTypes = {
  id: PropTypes.string,
};

ContactForm.defaultProps = {
  id: 'contact',
};

import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import TestimonialCard from '../testimonial-card';

export default function Testimonials(props) {
  return (
    <section className={`${styles.testimonials} ${props.className}`} id={props.id}>
      <h2 className={styles.testimonials__title}>{props.title}</h2>
      <div className={styles.testimonials__items}>
        <div className={styles.testimonials__items__item}>
          <TestimonialCard
            avatar="/images/Chayenne.jpg"
            name="Chayenne"
            quote="Mijn huis voelt erg luxe en comfortabel. Ik kan mijn apparaten nu gemakkelijk uit een app bedienen. Als ik vragen heb kan ik altijd contact opnemen met De Slimmeriken. Ik raadt dit bedrijf aan, zowel voor particulieren met een huis als voor bedrijven en kantoren."
          ></TestimonialCard>
        </div>
        <div className={styles.testimonials__items__item}>
          <TestimonialCard
            avatar="/images/Frank.jpg"
            name="Frank"
            quote="Ik ben blij dat ik nu al mijn lichten, thermostaat en beveiliging kan bedienen vanuit één app. Daarnaast hebben De Slimmeriken scenario's gemaakt aan de hand van mijn ritme in huis. Zonder hun hulp had ik dit niet voor elkaar gekregen. Ben erg tevreden over de manier van werken."
          ></TestimonialCard>
        </div>
      </div>
    </section>
  );
}

Testimonials.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
};

Testimonials.defaultProps = {
  title: 'Wat onze klanten zeggen',
  id: 'reviews',
};

import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

export default function InformationBlock(props) {
  return (
    <section className={`${styles.information} ${props.className}`} id={props.id}>
      <div className={styles.information__text}>
        <h2 className={styles.information__text__title}>{props.title}</h2>
        <p className={styles.information__text__paragraph}>{props.paragraph}</p>
      </div>
      <img className={styles.information__image} src={props.image} alt={props.title}></img>
    </section>
  );
}

InformationBlock.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  paragraph: PropTypes.string,
  image: PropTypes.string,
};

InformationBlock.defaultProps = {
  id: 'informatie',
  title: 'Waarom uw Smart Home laten ontwerpen en installeren?',
  paragraph:
    "Slimme huizen of te wel Smart Homes zijn in opkomst. Maar waarom werken alle slimme producten dan nog apart van elkaar? De Slimmeriken zorgt ervoor dat alle slimme producten met elkaar kunnen samenwerken en te bedienen zijn vanuit één app. Daarnaast hebben wij ons gespecialiseerd in huisautomatisering. Wij integreren de verschillende systemen zodat u echt kunt genieten van een slim huis. Door het programmeren van scenario's hoeft u nooit meer het licht aan te zetten of de thermostaat te bedienen. Deze slimme producten kunnen wij naar uw wens automatiseren. Lees verder om alle mogelijkheden te zien en doe de check.",
  image: '/images/smart-thermostat.jpg',
};

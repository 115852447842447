import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

export default function AdvantageColumn(props) {
  return (
    <div className={styles.advantage}>
      <h3 className={styles.advantage__title}>{props.title}</h3>
      <ul className={styles.advantage__list}>
        {props.children.map((item) => {
          return (
            <li key={item} className={styles.advantage__list__item}>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

AdvantageColumn.propTypes = {
  title: PropTypes.string,
};

AdvantageColumn.defaultProps = {
  title: 'Veiligheid',
};

import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import AdvantageColumn from '../advantage-column';

export default function Advantages(props) {
  return (
    <section className={`${styles.advantages} ${props.className}`} id={props.id}>
      <img
        className={styles.advantages__illustration}
        src={props.illustration}
        alt={props.title}
        title={props.title}
      ></img>
      <h2 className={styles.advantages__title}>{props.title}</h2>
      <div className={styles.advantages__items}>
        <AdvantageColumn title="Comfort">
          {[
            'Aanpasbare sfeervolle verlichting',
            'Een optimaal binnenklimaat',
            'Bediening vanuit één app',
            'Optimale veiligheid',
            'Inzicht in activiteit in en om uw huis',
          ]}
        </AdvantageColumn>
        <AdvantageColumn title="Energiebesparend">
          {[
            'Apparaten staan nooit onnodig aan',
            'Verwarm alleen als u (bijna) thuis bent',
            'Klimaat is per kamer te regelen',
            'Energiezuinige verlichting',
            'Zelf stroom opwekken',
          ]}
        </AdvantageColumn>
        <AdvantageColumn title="Huisautomatisering">
          {[
            "Geprogrammeerde scenario's",
            'Alle slimme apparaten werken samen',
            'Apparaten bedienen met spraak en een app',
            'Bediening op afstand',
            'Toekomstbestendig',
          ]}
        </AdvantageColumn>
      </div>
    </section>
  );
}

Advantages.propTypes = {
  id: PropTypes.string,
  illustration: PropTypes.string,
  title: PropTypes.string,
};

Advantages.defaultProps = {
  id: 'voordelen',
  illustration: '/illustrations/illustration-sunny-homes.svg',
  title: 'Voordelen van een Smart Home',
};

import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

export default function ProductCard(props) {
  return (
    <div className={styles.product}>
      <img
        className={styles.product__icon}
        src={props.icon}
        alt={props.name}
        title={props.name}
      ></img>
      <h3 className={styles.product__name}>{props.name}</h3>
    </div>
  );
}

ProductCard.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
};

ProductCard.defaultProps = {
  icon: '/symbols/icon-lightbulb.svg',
  name: 'Verlichting',
};

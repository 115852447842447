import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import PrimaryButton from '../primary-button';

export default function Advice(props) {
  return (
    <section className={`${styles.advice} ${props.className}`} id={props.id}>
      <img
        className={styles.advice__illustration}
        src={props.illustration}
        alt={props.title}
        title={props.title}
      ></img>
      <div className={styles.advice__text}>
        <h2 className={styles.advice__text__title}>{props.title}</h2>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdvxYnro6qbilkLLmHFHzFktOI9iRDEGsh4gQhsJwO3saVa0A/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PrimaryButton>Doe nu de check</PrimaryButton>
        </a>
      </div>
    </section>
  );
}

Advice.propTypes = {
  id: PropTypes.string,
  illustration: PropTypes.string,
  title: PropTypes.string,
};

Advice.defaultProps = {
  id: 'advies',
  illustration: '/illustrations/illustration-advise.svg',
  title: 'Vrijblijvend advies op maat?',
};

import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

export default function TestimonialCard(props) {
  return (
    <div className={styles.testimonial}>
      <div className={styles.testimonial__customer}>
        <img
          className={styles.testimonial__customer__avatar}
          src={props.avatar}
          alt={props.name}
          title={props.name}
        ></img>
        <p className={styles.testimonial__customer__name}>{props.name}</p>
      </div>
      <p className={styles.testimonial__quote}>
        <q>{props.quote}</q>
      </p>
    </div>
  );
}

TestimonialCard.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  quote: PropTypes.string,
};

TestimonialCard.defaultProps = {
  avatar: 'https://res.cloudinary.com/dev-kitchen/image/upload/v1586420949/hello-plant_kv3txx.jpg',
  name: 'Anne-Marlies',
  quote:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
};

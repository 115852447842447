import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

export default function StepColumn(props) {
  return (
    <div className={styles.step}>
      <img src={props.illustration} alt={props.title}></img>
      <h3 className={styles.step__title}>{props.title}</h3>
      <p className={styles.step__paragraph}>{props.paragraph}</p>
    </div>
  );
}

StepColumn.propTypes = {
  illustration: PropTypes.string,
  title: PropTypes.string,
  paragraph: PropTypes.string,
};

StepColumn.defaultProps = {
  illustration: '/illustrations/illustration-expert.svg',
  title: '1. Huisbezoek & advies',
  paragraph:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
};

import React from 'react';
import styles from './styles.module.scss';
import SecondaryButton from '../secondary-button';
import PropTypes from 'prop-types';

export default function SmartHome(props) {
  return (
    <section className={`${styles.smart_home} ${props.className}`} id={props.id}>
      <img
        className={styles.smart_home__illustration}
        src={props.illustration}
        alt={props.title}
        title={props.title}
      ></img>
      <div className={styles.smart_home__text}>
        <h2 className={styles.smart_home__text__title}>{props.title}</h2>
        <a href="/documents/Smart Home Gids - De Slimmeriken.pdf" download>
          <SecondaryButton>Download</SecondaryButton>
        </a>
      </div>
    </section>
  );
}

SmartHome.propTypes = {
  id: PropTypes.string,
  illustration: PropTypes.string,
  title: PropTypes.string,
};

SmartHome.defaultProps = {
  id: 'download',
  illustration: '/illustrations/illustration-reading.svg',
  title: 'Download onze Smart Home Gids',
};

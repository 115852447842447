import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

export default function SecondaryButton(props) {
  return (
    <button
      id={props.id}
      className={styles.secondary_button}
      type={props.type}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

SecondaryButton.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
};

SecondaryButton.defaultProps = {
  id: 'button',
  text: 'Download',
  type: 'button',
};

import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './accordion.css';

export default function Faq(props) {
  return (
    <section id={props.id} className={`${styles.faq} ${props.className}`}>
      <div className={styles.faq__container}>
        <h2 className={styles.faq__container__title}>{props.title}</h2>
        <Accordion allowZeroExpanded={true}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Wat is een Smart Home?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Er zijn meerder begrippen zoals Smart Home, slim huis, domotica en internet of
                things. Deze begrippen kunnen allemaal worden gebruikt voor het omschrijven van
                producten die met elkaar kunnen verbinden/samenwerken en die via een commando kunnen
                worden aangestuurd. Zo kunt u bijvoorbeeld met uw smartphone het licht, de
                rolluiken, de temperatuur, huishoudelijke apparaten, de deurbel en nog veel meer
                bedienen.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Wat is huisautomatisering?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Huisautomatisering is het automatiseren van elementen van een huis om de comfort,
                veiligheid en energiebesparing te verhogen.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Wat zijn Smart Home scenario’s?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Een Smart Home scenario is de benaming voor de acties die alle slimme producten
                uitvoeren op basis van een commando. Bijvoorbeeld als u het huis verlaat kunt u met
                een knop in de app de verwarming en verlichting uitschakelen en het alarmsysteem
                inschakelen. Wij maken de scenario’s op basis van uw wensen. Alle denkbare
                scenario’s zijn mogelijk.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Kunnen alle slimme producten met elkaar samenwerken?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Wij beloven dat wij alle slimme producten met elkaar samen kunnen laten werken.
                Bovendien kan alles worden bediend uit één app. Daarnaast kunnen deze slimme
                producten ook scenario’s met elkaar vormen.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Hoe garanderen jullie dat alle slimme producten samenwerken?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Wij zijn een onafhankelijke partij. Wij werken met verschillende merken producten en
                daardoor zijn de mogelijkheden eindeloos. Met onze goed opgeleide Slimmeriken
                programmeren wij zelf de producten en scenario’s. Op die manier is uw Smart Home
                altijd naar eigen wens in te richten.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Kunnen kantoren ook slim gemaakt worden?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Ja, dat kan! Dit noemen wij Smart Building. Door de juiste slimme producten te
                installeren is het mogelijk om data over het gebouw te verzamelen en deze om te
                zetten in energiebesparende mogelijkheden. De aanwezigheid van medewerkers kan
                worden gedetecteerd en op basis daarvan kan een ruimte worden verlicht, geventileerd
                of verwarmt. Dit kan enorme besparingen opleveren.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Wat kosten de diensten van De Slimmeriken?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Wij leveren altijd maatwerk, daardoor hangen de kosten van uw wensen af. Tijdens het
                offerte traject stellen we een pakket samen op basis van uw wensen. In de offerte
                specificeren wij alle kosten voor u. Neem contact met ons op en we vertellen u graag
                meer.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  );
}

Faq.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
};

Faq.defaultProps = {
  id: 'faq',
  title: 'Veelgestelde vragen',
};

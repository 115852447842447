import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import ProductCard from '../product-card';

export default function Products(props) {
  return (
    <section className={`${styles.products} ${props.className}`} id={props.id}>
      <h2 className={styles.products__title}>{props.title}</h2>
      <div className={styles.products__items}>
        <ProductCard name="Verlichting" icon="/symbols/icon-lightbulb.svg"></ProductCard>
        <ProductCard name="Klimaat" icon="/symbols/icon-climate.svg"></ProductCard>
        <ProductCard name="Beveiliging" icon="/symbols/icon-security.svg"></ProductCard>
        <ProductCard name="Speakers" icon="/symbols/icon-speaker.svg"></ProductCard>
        <ProductCard name="Ventilatie" icon="/symbols/icon-ventilation.svg"></ProductCard>
        <ProductCard
          name="Huishoudelijke Apparaten"
          icon="/symbols/icon-home-appliance.svg"
        ></ProductCard>
        <ProductCard name="Smart Hub" icon="/symbols/icon-hub.svg"></ProductCard>
        <ProductCard name="Automatisering" icon="/symbols/icon-automation.svg"></ProductCard>
        <ProductCard name="Spraakbesturing" icon="/symbols/icon-speach.svg"></ProductCard>
        <ProductCard name="WiFi" icon="/symbols/icon-wifi.svg"></ProductCard>
      </div>
    </section>
  );
}

Products.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
};

Products.defaultProps = {
  title: 'Oplossingen',
  id: 'oplossingen',
};
